


































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  required,
  numeric,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import { namespace } from 'vuex-class'
const PlaceTypesStore = namespace('PlaceTypesStore')
const LocationsStore = namespace('LocationsStore')

import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import { LocationsModel } from '@/core/models/LocationsModel'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import BaseTextarea from '@/components/forms/BaseTextarea.vue'
import BaseSelect from '@/components/forms/BaseSelect/BaseSelect.vue'
import Field from '@/components/forms/Field.vue'
import { StepOneInterface } from '@/store/modules/room.module'

const validations = {
  name: { required, minLength: minLength(1), maxLength: maxLength(250) },
  description: {
    required,
    minLength: minLength(150),
    maxLength: maxLength(3000),
  },
  capacity: { required, numeric },
  area: { required, numeric },
  placeType: { required },
  address: { required },
}

@Component({
  name: 'StepOne',
  components: {
    FormGroup,
    Field,
    BaseButton,
    BaseCheckbox,
    BaseTextarea,
    BaseIcon,
    BaseSelect,
  },
  validations,
})
export default class StepOne extends Vue {
  @Prop({ required: true })
  value!: StepOneInterface

  name = ''
  placeType = ''
  description = ''
  capacity: number | null = null
  area: number | null = null
  address = ''
  active = true
  show_schedule = false

  @Watch('value')
  onValueChanged(val: StepOneInterface): void {
    this.updateModel(val)
  }

  get descriptionErrorText(): string | null {
    const description = (this.$v as any)?.description
    const isDirty = description.$dirty

    if (isDirty && !description?.required) {
      return 'Поле не заполнено'
    } else if (isDirty && !description?.minLength) {
      return 'Минимальная длина описания - 150 символов'
    } else if (isDirty && !description?.maxLength) {
      return 'Максимальная длина описания - 3000 символов'
    }

    return null
  }

  @PlaceTypesStore.Getter
  public placeTypesList!: PlaceTypesModel

  @PlaceTypesStore.Action
  private getPlaceTypes!: () => Promise<PlaceTypesModel>

  @LocationsStore.Getter
  public LocationsList!: LocationsModel

  @LocationsStore.Action
  private getLocations!: () => Promise<LocationsModel>

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }

  public updateModel(data: StepOneInterface): void {
    this.name = data?.name ?? ''
    this.placeType = data?.placeType ?? ''
    this.description = data?.description ?? ''
    this.capacity = data?.capacity ?? null
    this.area = data?.area ?? null
    this.address = data?.address ?? ''
    this.active = data?.active ?? false
  }

  public update(): void {
    this.$emit('input', {
      name: this.name,
      placeType: this.placeType,
      description: this.description,
      capacity: this.capacity,
      area: this.area,
      address: this.address,
      active: this.active,
    })
  }

  public created(): void {
    this.getLocations()
    this.getPlaceTypes()
  }

  public mounted(): void {
    this.updateModel(this.value)
  }
}
